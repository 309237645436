import * as api from '../services/api'
import {
  APP_POP_SNACK_BAR_MESSAGE,
  APP_PUSH_SNACK_BAR_MESSAGE,
  APP_SET_IS_SNACK_BAR_OPEN,
} from '../actionTypes'

export function login ({username, password, remember}) {
  return () => {
    return api.postLogin(username, password)
    // return api.postLogin(username, password)
  }
}

export function register ({username, password, details}) {
  return () => {
    console.log("register:", username, password, details)
    return api.postRegister(username, password, details)
    // console.log('register actions')
    // return api.postRegister(username, password)
  }
}


function processSnackbarQueue () {
  return (dispatch, getState) => {
    const {app} = getState()
    if (app.snackBarMessages.length > 0) {
      dispatch({type: APP_POP_SNACK_BAR_MESSAGE})
      dispatch({type: APP_SET_IS_SNACK_BAR_OPEN, payload: {isSnackbarOpen: true}})
    }
  }
}

export function openSnackbar (message) {
  return (dispatch, getState) => {
    dispatch({type: APP_PUSH_SNACK_BAR_MESSAGE, payload: {message}})

    const {app} = getState()

    if (app.isSnackbarOpen) {
      dispatch({type: APP_SET_IS_SNACK_BAR_OPEN, payload: {isSnackbarOpen: false}})
    } else {
      dispatch(processSnackbarQueue())
    }
  }
}

export function onSnackbarClose () {
  return {
    type: APP_SET_IS_SNACK_BAR_OPEN,
    payload: {
      isSnackbarOpen: false
    }
  }
}

export function onSnackbarExited () {
  return (dispatch) => {
    dispatch(processSnackbarQueue())
  }
}