import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import classNames from 'classnames'

const styles = theme => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  },
})

const LoadingButton = ({classes, isLoading, children, buttonClass, loaderSize = 24, isDisabled, ...buttonProps}) => (
  <div className={classNames(classes.wrapper, buttonClass)}>
    <Button
      disabled={isDisabled || isLoading}
      {...buttonProps}
    >
      {children}
    </Button>
    {isLoading && <CircularProgress
      size={loaderSize}
      className={classes.buttonProgress}
      style={{marginTop: -loaderSize / 2, marginLeft: -loaderSize / 2}}
    />}
  </div>
)

export default withStyles(styles)(LoadingButton)
