const validator = require('validator')
const isEmpty = require('lodash/isEmpty')

module.exports = data => {
  let errors = {}
  const {username, email, password, passwordConfirmation, timezone} = data

  if (!username || validator.isEmpty(username)) {
    errors.username = 'This field is required'
  }

  if (!email || validator.isEmpty(email)) {
    errors.email = 'This field is required'
  } else if (!validator.isEmail(email)) {
    errors.email = 'Email is invalid'
  }

  if (!password || validator.isEmpty(password)) {
    errors.password = 'This field is required'
  }

  if (!passwordConfirmation || validator.isEmpty(passwordConfirmation)) {
    errors.passwordConfirmation = 'This field is required'
  }


  if (password && passwordConfirmation && !validator.equals(password, passwordConfirmation)) {
    errors.passwordConfirmation = 'Passwords must match'
  }
  //
  // if (!timezone || validator.isEmpty(data.timezone)) {
  //   errors.timezone = 'This field is required'
  // }

  return {
    errors: errors,
    isValid: isEmpty(errors)
  }
}
