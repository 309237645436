import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import TextField from 'redux-form-material-ui/es/TextField'
import {Link as RouterLink, withRouter} from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import LoadingButton from '../../components/LoadingButton'
import validator from '../../../validator/login'

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  button: {
    marginTop: theme.spacing(3)
  }
})

const LoginForm = (props) => {
  const { pristine, reset, valid, submitting, classes, values, handleSubmit } = props

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="username"
        component={TextField}
        label={'Username'}
        margin={'normal'}
        fullWidth={true}
        variant={'outlined'}
      />

      <Field
        name="password"
        type="password"
        component={TextField}
        label={'Password'}
        margin={'normal'}
        fullWidth={true}
        variant={'outlined'}
      />

      <LoadingButton color={'primary'} variant="contained" type='submit' fullWidth={true}
                     disabled={pristine || submitting} buttonClass={classes.button}>Login</LoadingButton>
    </form>
  )
}

export default withStyles(styles)(
  reduxForm({
    form: 'loginForm',
    validate: (values) => validator(values).errors
  })(LoginForm)
)