import {
  // NAV_TOGGLE_MENU,
  // NAV_CLOSE_MENU,
  APP_SET_IS_SNACK_BAR_OPEN,
  APP_PUSH_SNACK_BAR_MESSAGE,
  APP_POP_SNACK_BAR_MESSAGE
} from '../actionTypes'

const initialState = {
  // isMenuOpen: false,
  isSnackbarOpen: false,
  snackBarMessage: '',
  snackBarMessages: []
}

export default function reducer (state = initialState, action = {}) {

  switch (action.type) {
    // case NAV_TOGGLE_MENU:
    //   return {...state, isMenuOpen: !state.isMenuOpen}
    // case NAV_CLOSE_MENU:
    //   return {...state, isMenuOpen: false}
    case APP_PUSH_SNACK_BAR_MESSAGE:
      return {...state, snackBarMessages: [...state.snackBarMessages, action.payload.message]}
    case APP_POP_SNACK_BAR_MESSAGE:
      return {
        ...state,
        snackBarMessage: state.snackBarMessages[0],
        snackBarMessages: [...state.snackBarMessages.slice(1)]
      }
    case APP_SET_IS_SNACK_BAR_OPEN:
      return {...state, isSnackbarOpen: action.payload.isSnackbarOpen}

    default:
      return state
  }
}
