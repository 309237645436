import axios from "axios"
const PATH_API = '/api'
const PATH_LOGIN = '/login'
const PATH_REGISTER = '/register'

const submit = async (method, path, data, contentType = 'application/json') => {

  const header = { contentType }
  const request = { method: method }

  if (data) {
    if (contentType.indexOf('json') !== -1) {
      request.body = JSON.stringify(data)
      request.headers = {
        'Content-Type': contentType
      }
    }
  }

  const response = await axios({
    method: method,
    url: path,
    data,
    header
  })

  return response.data
}

export const postLogin = async (username, password, details) => {
  return submit('POST', PATH_LOGIN, {username, password, details})
  // return submit('POST', PATH_LOGIN, {username, password})
}

export const postRegister = async (username, password) => {
  return await submit('POST', PATH_REGISTER, {username, password})
  // return submit('POST', PATH_REGISTER, {username, password})
}