import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
// import pink from 'material-ui/colors/pink'
// import red from 'material-ui/colors/red'
// import blueGrey from 'material-ui/colors/blueGrey'
// import {teal, lightGreen} from '@material-ui/core/colors'
const color = {
  grey: '#dddfd4',
  yellow: '#fae596',
  green: '#3fb0ac',
  darkGreen: '#173e43'
}

export default createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'light'
  // palette: {
  //   // primary: color.grey,
  //   // secondary: color.green
  //   primary: {
  //     main: color.green
  //     // main: color.grey,
  //   },
  //   secondary: {
  //     main: color.darkGreen
  //     // main: color.green
  //   }
  },
})
