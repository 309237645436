const validator = require('validator')
const isEmpty = require('lodash/isEmpty')

module.exports = data => {
  let errors = {}
  const {username, password} = data

  if (!username || validator.isEmpty(username)) {
    errors.username = 'This field is required'
  }

  if (!password || validator.isEmpty(password)) {
    errors.password = 'This field is required'
  }

  return {
    errors: errors,
    isValid: isEmpty(errors)
  }
}
