import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
// import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { login, openSnackbar } from '../actions/app'
import { SubmissionError } from 'redux-form'
import DocumentTitle from 'react-document-title'
// import PersonAddIcon from '@material-ui/icons/PersonAdd'

import LoginForm from '../components/LoginForm'


const styles = theme => ({
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    marginBottom: '16px'
  },
  // title: {
  //   textAlign: 'center'
  // },
  pageContainer: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    flex: 1,
    maxWidth: 480
  },
})

const LoginPage = (props) => {
  const dispatch = useDispatch()

  const _onRegisterClick = () => {
    this.props.history.push('/register')
  }

  const _handleSubmit = async ({username, password}) => {
    try {
      await dispatch(login({username, password}))
      window.location.replace('/')
    } catch (err) {
      const {response: res} = err
      console.log(res)
      if (res && res.status === 401 && res.data) {
        dispatch(openSnackbar(res.data.message))
        throw new SubmissionError(res.data.data)

      } else {
        dispatch(openSnackbar('Network error'))
      }
    }
  }

  const {classes} = props

  return (
    <Grid container justify={'center'} alignItems={'center'}>
      <Grid item className={classes.pageContainer}>
        <Paper className={classes.paper}>
          <Typography variant="h3" gutterBottom align='center'>Login</Typography>
          <LoginForm onSubmit={_handleSubmit} />
        </Paper>
        {/*    </Zoom>*/}
        {/*    /!*<Grid container justify={'center'} spacing={0}>*!/*/}
        {/*    /!*<Grid item>*!/*/}
        {/*    /!*<Button onClick={this._onRegisterClick}><PersonAddIcon className={classes.buttonIcon}*!/*/}
        {/*    /!*fontSize={'small'} /> Register</Button>*!/*/}
        {/*    /!*</Grid>*!/*/}
        {/*    /!*</Grid>*!/*/}
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(withRouter(LoginPage))
