import { hot } from 'react-hot-loader/root'
import React, {useState, useEffect} from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router' // react-router v4/v5
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import Snackbar from '@material-ui/core/Snackbar'
import { onSnackbarClose, onSnackbarExited } from './actions/app'
// import './index.scss'
import LoginPage from './pages/LoginPage'
import RegisterPage from './pages/RegisterPage'

const SNACK_BAR_AUTO_HIDE_DURATION = 5000

const styles = theme => ({
  '@global': {
    html: {
      position: 'relative',
      height: '100%'
    },
    body: {
      minHeight: '100%',
      display: 'flex',
    },
    '#app': {
      flex: 1,
      display: 'flex',
      overflow: 'hidden'
    }
  },
  container: {
    flex: 1,
    display: 'flex',
    width: '100%'
  }
})

const Main = (props) => {
  const { classes } = props

  const { isSnackbarOpen, snackBarMessage} = props
  return (
    <div className={classes.container}>
      <Switch>
        {/*<NavigationBar />*/}
        {/*<FlashMessagesList />*/}
        <Route exact path='/register' component={RegisterPage} />
        <Route exact path='/login' component={LoginPage} />
        <Redirect to={'/login'} />
      </Switch>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={isSnackbarOpen}
        autoHideDuration={SNACK_BAR_AUTO_HIDE_DURATION}
        onClose={onSnackbarClose}
        onExited={onSnackbarExited}
        key={snackBarMessage}
        message={snackBarMessage}
      />
    </div>
  )
}

const connectedMain = withRouter(connect(state => ({
  isSnackbarOpen: state.app.isSnackbarOpen,
  snackBarMessage: state.app.snackBarMessage
}), {
  onSnackbarClose,
  onSnackbarExited
})(Main))

const styledMain = withStyles(styles)(connectedMain)

export default hot(styledMain)