import React from 'react'
import ReactDOM from 'react-dom'
import { Route, Switch } from 'react-router' // react-router v4/v5
import { ConnectedRouter } from 'connected-react-router'
import { JssProvider } from 'react-jss'
import { Provider } from 'react-redux'
import { createGenerateClassName, ThemeProvider } from '@material-ui/styles'
import { CssBaseline }  from '@material-ui/core'
import configureStore, { history } from './configureStore'
import theme from '../theme'
import Main from './Main'

const store = configureStore(/* provide initial state if any */)
const generateClassName = createGenerateClassName()

ReactDOM.render(
  <JssProvider generateClassName={generateClassName}>
    <ThemeProvider theme={theme}>
      {/*<CssBaseline />*/}
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Main/>
        </ConnectedRouter>
      </Provider>
    </ThemeProvider>
  </JssProvider>,
  document.getElementById('app')
)