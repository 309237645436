import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import app from './reduceers/app'
import { reducer as formReducer } from 'redux-form'

// import data from './reducers/data'
// import raceHorse from './reducers/raceHorse'
// import rating from './reducers/rating'

export default (history) => combineReducers({
  router: connectRouter(history),
  app,
  form: formReducer

  // data,
  // raceHorse,
  // rating
})